import { getAlgoliaResults } from '@algolia/autocomplete-js';
import { algoliasearch } from 'algoliasearch';
import Autocomplete from './Autocomplete';
import SearchItem from './SearchItem';
import React, { useEffect, useState } from 'react';

const appId = '4W8YGFKU2H';
const apiKey = 'd19bf71225d5aab6d8f9e6bb98a88733';
export const searchClient = algoliasearch(appId, apiKey) as any;

function Core({ pathname }) {
  const [pc, setPc] = useState(true);

  useEffect(() => {
    screen.width < 900 ? setPc(false) : setPc(true);
  }, []);

  return (
    <div className="navbar-search" style={{ width: '500px', padding: '10px' }}>
      <Autocomplete
        openOnFocus={pc}
        placeholder={`Search for ${pathname}`}
        getSources={({ query }: { query: string }) => [
          {
            sourceId: pathname,
            getItems() {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: pathname,
                    params: {
                      query,
                      hitsPerPage: 10
                    }
                  }
                ]
              });
            },
            templates: {
              item({ item, components }) {
                return (
                  <SearchItem
                    hit={item}
                    components={components}
                    pathname={pathname}
                  />
                );
              },
              noResults() {
                return 'No results found!';
              },
              footer() {
                return (
                  <button
                    style={{
                      marginTop: '10px',
                      width: '100%',
                      padding: '10px',
                      backgroundColor: '#007bff',
                      color: '#fff',
                      border: 'none',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      window.location.href = `/all-${pathname}-results?query=${query}`;
                    }}
                  >
                    Show All Results
                  </button>
                );
              }
            }
          }
        ]}
      />
    </div>
  );
}

export default Core;

import React, { useEffect, useRef } from 'react';
import { Field, useField } from 'formik';

export interface IFormFieldProps {
  ref?: React.Ref<HTMLInputElement>;
  name?: string;
  type?: string;
  placeholder?: string;
  value?: string;
  label?: string;
  labelRequired?: boolean;
  hasAddons?: JSX.Element;
  multiline?: boolean;
  autoResize?: boolean; // New prop for resize functionality
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  additionalControlClasses?: string[];
  additionalFieldClasses?: string[];
  additionalInputClasses?: string;
  displayErrorMessage?: boolean;
  onChange?: (eventOrTextValue: string | React.ChangeEvent<any>) => void;
  onBlur?: (e: React.ChangeEvent<any>) => void;
}

interface ICheckboxProps {
  name: string;
  value: string;
  label: string;
}

export const Checkbox = (props: ICheckboxProps) => {
  return (
    <Field name={props.name}>
      {({ field, form }) => {
        const isValueChecked = field.value.includes(props.value);
        return (
          <label className="checkbox checkbox-container is-small">
            {props.label}
            <input
              type="checkbox"
              {...props}
              checked={isValueChecked}
              onChange={() => {
                // Toggles the selected values in the current field
                if (isValueChecked) {
                  const removeCurrentValues = field.value.filter(
                    (value: string | number | string[]) => value !== props.value
                  );
                  form.setFieldValue(props.name, removeCurrentValues);
                } else {
                  const addCurrentValue = field.value.concat(props.value);
                  form.setFieldValue(props.name, addCurrentValue);
                }
              }}
            />
            <span className="checkmark is-absolute m-l-0" />
          </label>
        );
      }}
    </Field>
  );
};

const FormField: any = React.forwardRef(
  (props: IFormFieldProps, ref: React.Ref<HTMLInputElement>) => {
    const [field, meta] = useField(props.name!);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const resizeTimeoutRef = useRef<number>();

    const adjustHeight = () => {
      const textarea = textareaRef.current;
      if (!textarea) return;

      if (resizeTimeoutRef.current) {
        window.clearTimeout(resizeTimeoutRef.current);
      }

      resizeTimeoutRef.current = window.setTimeout(() => {
        textarea.style.height = 'auto';
        const maxHeight = 120;
        const newHeight = Math.min(
          Math.max(textarea.scrollHeight, 40),
          maxHeight
        );
        textarea.style.height = `${newHeight}px`;
        textarea.style.overflowY =
          textarea.scrollHeight > maxHeight ? 'auto' : 'hidden';
      }, 0);
    };

    useEffect(() => {
      if (props.multiline && props.autoResize && textareaRef.current) {
        adjustHeight();

        let rafId: number;
        const resizeObserver = new ResizeObserver(() => {
          if (rafId) return;
          rafId = window.requestAnimationFrame(() => {
            adjustHeight();
            rafId = 0;
          });
        });

        resizeObserver.observe(textareaRef.current);

        return () => {
          resizeObserver.disconnect();
          if (rafId) window.cancelAnimationFrame(rafId);
          if (resizeTimeoutRef.current) {
            window.clearTimeout(resizeTimeoutRef.current);
          }
        };
      }
    }, [field.value, props.autoResize]);

    const inputElement = props.multiline ? (
      <textarea
        ref={textareaRef}
        className={`textarea ${props.additionalInputClasses} ${
          meta.error && meta.touched && 'input-error'
        }`}
        {...field}
        placeholder={props.placeholder}
        style={{ resize: props.autoResize ? 'none' : undefined }}
      />
    ) : (
      <input
        className={`input ${props.additionalInputClasses} ${
          props.displayErrorMessage &&
          meta.error &&
          meta.touched &&
          'input-error'
        }`}
        {...field}
        type={props.type}
        ref={ref}
        placeholder={props.placeholder}
      />
    );

    return (
      <div className={`field ${props.additionalFieldClasses?.join(' ') ?? ''}`}>
        {props.label && (
          <label
            className={`label ${props.additionalFieldClasses?.join(' ') ?? ''}`}
          >
            {props.label}
            {props.labelRequired && <span className="has-text-danger">*</span>}
          </label>
        )}
        <div
          className={`control ${
            props.additionalControlClasses?.join(' ') ?? ''
          }`}
        >
          {props.iconLeft}
          {inputElement}
          {props.iconRight}
        </div>
        {props.hasAddons}
        {props.displayErrorMessage && meta.error && meta.touched && (
          <span className="field-error has-text-danger">{meta.error}</span>
        )}
      </div>
    );
  }
);

FormField.defaultProps = {
  additionalControlClasses: [],
  additionalInputClasses: '',
  displayErrorMessage: true,
  multiline: false,
  autoResize: false
};

export default FormField;

import { gql } from '@apollo/client';

export const TOGGLE_FOLLOW = gql`
  mutation TOGGLE_FOLLOW($projectId: String!) {
    toggleFollow(projectId: $projectId) {
      id
      followers {
        id
        followBy {
          id
          profile {
            name
          }
        }
      }
    }
  }
`;

import React, { useEffect, useState } from 'react';
import { searchClient } from '../../components/Search/core';

function AllProjectResultsPage() {
  const [results, setResults] = useState([]);
  const query = new URLSearchParams(window.location.search).get('query') as any;

  useEffect(() => {
    const fetchResults = async () => {
      const response = await searchClient.search([
        {
          indexName: 'project',
          params: {
            query,
            hitsPerPage: 10,
            page: 0
          }
        }
      ]);

      setResults(response.results[0].hits);
    };

    fetchResults();
  }, [query]);

  return (
    <div>
      {results.map((result: any, index: number) => (
        <div key={index}>
          <h1 style={{ color: 'blue', fontWeight: 'bold' }}>{result.title}</h1>
          <p>{result.description}</p>
          <br />
        </div>
      ))}
    </div>
  );
}

export default AllProjectResultsPage;

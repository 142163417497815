import type { FormikValues } from 'formik';
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { GET_CURRENT_USER } from '../../../graphql/GET_CURRENT_USER';
import { GET_CURRENT_USER_me } from '../../../graphql/types/GET_CURRENT_USER';
import { GET_USER_getUser } from '../../../graphql/types/GET_USER';
import { UPDATE_USER_PROFILE } from '../../../graphql/UPDATE_USER_PROFILE';
import FormField from '../../FormField';
import UserAvatar from './UserAvatar';
import UserCollaborateWith from './UserCollaborateWith';
import UserProfessionForm from './UserProfessionForm';
import UserSkillForm from './UserSkillForm';

interface IUserProfileFormProps {
  user: GET_USER_getUser | GET_CURRENT_USER_me;
  onClose: () => void;
}

const userProfileSchema = Yup.object().shape({
  name: Yup.string().required('This field is required.')
});

const UserProfileForm: React.FunctionComponent<IUserProfileFormProps> = (
  props: IUserProfileFormProps
) => {
  const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE, {
    onCompleted: () => toast.success('User profile successfully updated.'),
    refetchQueries: () => [
      {
        query: GET_CURRENT_USER
      }
    ]
  });

  const renderForm = ({
    handleSubmit,
    isSubmitting,
    values,
    handleChange,
    handleBlur
  }: FormikValues) => {
    return (
      <div className="modal-card page-user-profile-modal-card">
        <header className="modal-card-head has-background-white">
          <p className="modal-card-title">Update User profile</p>
          <button
            className="delete"
            aria-label="close"
            onClick={props.onClose}
          />
        </header>

        <section className="modal-card-body page-user-profile-modal-card-body">
          <div className="media m-b-1 page-user-profile-modal-card-avatar">
            <UserAvatar avatar={props.user.avatar} />
          </div>
          <div className="media m-b-1">
            <div className="media-content">
              <div className="content">
                <div className="field">
                  <div className="field-body">
                    <FormField
                      label="Your Name"
                      labelRequired={true}
                      type="text"
                      name="name"
                      placeholder={'Enter your name...'}
                      additionalFieldClasses={['is-size-8', 'is-uppercase']}
                      additionalInputClasses="has-background-white is-small"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <UserProfessionForm professions={props.user.professions} />
          <UserSkillForm skills={props.user.skills} />
          <form onSubmit={handleSubmit} id="userProfileForm">
            <div className="field">
              <div className="field-body">
                <FormField
                  label="About Yourself"
                  multiline={true}
                  name={'bio'}
                  placeholder={
                    'Eg. I am a web designer specializing in designing interactive websites. I would love to take my design work to the next level with a motion graphics artist. Coffee lover.'
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bio}
                  additionalFieldClasses={['is-size-8', 'is-uppercase']}
                  additionalInputClasses="page-user-profile-modal-card-body-textarea has-background-white is-small"
                />
              </div>
            </div>
            <UserCollaborateWith
              desiredCollaborationProfession={
                props.user.desiredCollaborationProfession!
              }
            />
            <div className="field">
              <div className="field-body">
                <FormField
                  label="Your Website"
                  name="website"
                  placeholder="https://website.com"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.website}
                  additionalFieldClasses={['is-size-8', 'is-uppercase']}
                  additionalInputClasses="has-background-white is-small"
                />
              </div>
            </div>
          </form>
        </section>
        <footer className="modal-card-foot page-user-profile-modal-card-footer has-background-white p-t-1 p-b-1">
          <button
            className={`button has-height-auto has-width-auto is-primary ${
              isSubmitting && 'is-loading'
            }`}
            type="submit"
            form="userProfileForm"
          >
            <span className="is-small has-text-weight-medium">
              Save changes
            </span>
          </button>
        </footer>
      </div>
    );
  };

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={userProfileSchema}
      initialValues={{
        bio: props.user.profile.bio ?? undefined,
        collaborationStatus:
          props.user.profile.collaborationStatus ?? undefined,
        name: props.user.profile.name ?? undefined,
        website: props.user.profile.website ?? undefined
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await updateUserProfile({ variables: values });
        setSubmitting(false);
        if (props.onClose) {
          props.onClose();
        }
      }}
    >
      {(formik) => renderForm(formik)}
    </Formik>
  );
};

export default UserProfileForm;

import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { GET_CURRENT_USER_me } from '../../../graphql/types/GET_CURRENT_USER';
import { TOGGLE_FOLLOW } from '../../../graphql/TOGGLE_FOLLOW';
import { ProjectFollower } from '../../../graphql/types/shared';
import { useRedirect } from '../../../hooks/useRedirect';
import followIcon from '../../../assets/images/icons/follow.png';
import { GET_PROJECT_getProject_followers } from '../../../graphql/types/GET_PROJECT';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

enum EPageTypes {
  list = 'list',
  project = 'project'
}

const MAX_USERS_TO_DISPLAY = 9;

interface IFollowProject {
  projectId: string;
  followers: ProjectFollower[];
  wasFollowedByUser: boolean;
  pageType?: string;
  projectAuthorId?: string;
  currentUserId?: string;
  user?: GET_CURRENT_USER_me;
}

const FollowProject: React.FC<IFollowProject> = ({
  projectId,
  followers,
  wasFollowedByUser,
  projectAuthorId,
  currentUserId,
  user
}: IFollowProject) => {
  const [fakeFollowers, setFakeFollowers] = useState({
    followersLength: followers?.length || 0,
    wasFollowed: wasFollowedByUser
  });

  const [isShowFollowUsers, setShowFollowUsers] = useState(false);
  const { redirectToHomePage } = useRedirect();

  const handleFakeFollow = () => {
    setFakeFollowers((prevFollow) => {
      const followLength = prevFollow.wasFollowed
        ? Math.max(0, prevFollow.followersLength - 1)
        : prevFollow.followersLength + 1;

      return {
        ...prevFollow,
        followersLength: followLength,
        wasFollowed: !prevFollow.wasFollowed
      };
    });
  };

  const [toggleFollow] = useMutation(TOGGLE_FOLLOW, {
    variables: {
      projectId
    },
    onError: () => {
      toast.error('Failed to update follow status');
      // Revert the fake state on error
      handleFakeFollow();
    }
  });

  const hasMoreFollowers = followers?.length > MAX_USERS_TO_DISPLAY;

  const handleFollow = async () => {
    if (!user) {
      redirectToHomePage();
      return;
    }

    try {
      handleFakeFollow();
      await toggleFollow();
    } catch (error) {
      console.error('Follow error:', error);
    }
  };

  return (
    <a
      className="post-card-stats-item tooltip has-text-weight-semibold is-inline-flex is-small"
      onClick={handleFollow}
      onMouseEnter={() => {
        if (currentUserId === projectAuthorId) {
          setShowFollowUsers(true);
        }
      }}
    >
      <img src={followIcon} alt="follow icon" />
      <span
        className={`${
          fakeFollowers.wasFollowed ? 'has-text-info' : 'has-text-grey-dark'
        }`}
      >
        {fakeFollowers.wasFollowed ? 'Following' : 'Follow'}
      </span>
      {fakeFollowers.followersLength > 0 && (
        <span className="is-size-7 is-light icon--is-inline-flex is-hover-underline m-l-1">
          {fakeFollowers.followersLength}
        </span>
      )}
      <FollowUserListTooltip
        isShowFollowUsers={isShowFollowUsers}
        followers={followers}
        hasMoreFollowers={hasMoreFollowers}
      />
    </a>
  );
};

interface IFollowUserListToolTip {
  isShowFollowUsers: boolean;
  followers: GET_PROJECT_getProject_followers[];
  hasMoreFollowers: boolean;
}

const FollowUserListTooltip = ({
  isShowFollowUsers,
  followers,
  hasMoreFollowers
}: IFollowUserListToolTip) => {
  if (isShowFollowUsers && followers?.length > 0) {
    return (
      <div className="tooltiptext is-size-8">
        {followers.map((follow, idx: number) => {
          if (idx < MAX_USERS_TO_DISPLAY && follow?.followBy?.profile) {
            return (
              <Link
                to={`/user/${follow.followBy.id}`}
                className="is-block tooltip-link"
                key={follow.id}
              >
                {follow.followBy.profile.name}
              </Link>
            );
          }
          return undefined;
        })}
        {hasMoreFollowers && (
          <span className="is-block has-text-grey-light">
            and {followers?.length - MAX_USERS_TO_DISPLAY} more...
          </span>
        )}
      </div>
    );
  }

  return (
    <div className="tooltiptext is-size-8">
      <span className="is-block">
        Click follow if you want to get updated about this project.
      </span>
    </div>
  );
};

FollowProject.defaultProps = {
  pageType: EPageTypes.list
};

export default FollowProject;

import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import likeIcon from '../../../assets/images/icons/like.png';
import { TOGGLE_LIKE } from '../../../graphql/TOGGLE_LIKE';
import { TOGGLE_UPDATE_LIKE } from '../../../graphql/TOGGLE_UPDATE_LIKE';
import { GET_CURRENT_USER_me } from '../../../graphql/types/GET_CURRENT_USER';
import { GET_PROJECT_getProject_likes } from '../../../graphql/types/GET_PROJECT';
import { useRedirect } from '../../../hooks/useRedirect';

enum EPageTypes {
  list = 'list',
  project = 'project',
  projectUpdate = 'projectUpdate'
}

interface ILikeProject {
  postId: string;
  likes: GET_PROJECT_getProject_likes[];
  wasLikedByUser: boolean;
  pageType?: string;
  user?: GET_CURRENT_USER_me;
}

const MAX_USERS_TO_DISPLAY = 9;

const LikeProject: React.FunctionComponent<ILikeProject> = ({
  postId,
  likes,
  wasLikedByUser,
  pageType,
  user
}: ILikeProject) => {
  const [fakeLike, setFakeLike] = useState({
    likesLength: likes.length,
    wasLiked: wasLikedByUser
  });

  const { redirectToHomePage } = useRedirect();

  // Faking the like method to improve the UI experience when like a project.
  const handleFakeLike = () => {
    setFakeLike((prevLike) => {
      const likesLength = prevLike.wasLiked
        ? prevLike.likesLength - 1
        : prevLike.likesLength + 1;

      return {
        ...prevLike,
        likesLength,
        wasLiked: !prevLike.wasLiked
      };
    });
  };

  const [toggleLike] = useMutation(TOGGLE_LIKE, {
    variables: {
      projectId: postId
    }
  });

  const [toggleProjectUpdateLike] = useMutation(TOGGLE_UPDATE_LIKE, {
    variables: {
      updateId: postId
    }
  });

  const hasMoreLikes = likes.length > MAX_USERS_TO_DISPLAY;

  const handleLike = async () => {
    if (!user) {
      redirectToHomePage();
      return;
    }
    handleFakeLike();

    if (pageType === 'project') {
      await toggleLike();
    } else if (pageType === 'projectUpdate') {
      await toggleProjectUpdateLike();
    } else {
      toast.error('There is something wrong happen when you like ...');
    }
  };

  return (
    <a
      className="post-card-stats-item tooltip has-text-weight-semibold is-inline-flex is-small"
      onClick={handleLike}
    >
      <img src={likeIcon} alt="like icon" />
      <span
        className={`${
          (fakeLike.wasLiked && 'has-text-info') || 'has-text-grey-dark'
        }`}
      >
        Like
      </span>
      {likes.length > 0 && (
        <>
          <span className="is-size-7 is-light icon--is-inline-flex is-hover-underline">
            {fakeLike.likesLength}
          </span>
          <div className="tooltiptext is-size-8">
            {likes.map((like: GET_PROJECT_getProject_likes, idx: number) => {
              if (idx < MAX_USERS_TO_DISPLAY) {
                return (
                  <span className="is-block" key={like.id}>
                    {like.author.profile.name}
                  </span>
                );
              }

              return undefined;
            })}

            {hasMoreLikes && (
              <span className="is-block" key={`more-${likes.length}`}>
                {`and ${likes.length - MAX_USERS_TO_DISPLAY} more...`}
              </span>
            )}
          </div>
        </>
      )}
    </a>
  );
};

LikeProject.defaultProps = {
  pageType: EPageTypes.list
};

export default LikeProject;

import type { ReactNode } from 'react';
import React, { createContext } from 'react';
import { useQuery } from '@apollo/client';

import { GET_PROJECT } from '../graphql/GET_PROJECT';
import { GET_PROJECT_getProject } from '../graphql/types/GET_PROJECT';
import { renderContents } from './common';

const initData: GET_PROJECT_getProject = {
  __typename: 'Project',
  author: null,
  collaborations: [],
  comments: [],
  createdAt: null,
  description: '',
  fundingStatus: '',
  isPublic: false,
  genres: [],
  id: '',
  interested: [],
  likes: [],
  locations: null,
  medias: [],
  progress: '',
  projectStatus: '',
  title: '',
  type: '',
  updatedAt: null,
  videoUrl: '',
  visibility: '',
  wasLikedByUser: false,
  updates: [],
  followers: [],
  wasFollowedByUser: false
};

export const ProjectContext = createContext(initData);

interface IProjectContextProviderProps {
  children: ReactNode;
  projectId: string;
}

export const ProjectContextProvider = (props: IProjectContextProviderProps) => {
  const { loading, error, data } = useQuery(GET_PROJECT, {
    variables: { projectId: props.projectId }
  });

  return (
    <ProjectContext.Provider
      value={data && data.getProject ? data.getProject : initData}
    >
      {renderContents({
        children: props.children,
        data,
        error,
        loading
      })}
    </ProjectContext.Provider>
  );
};

import React, { useEffect } from 'react';
// import { Switch } from 'react-router';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import ShareableRoute from './components/ShareableRoute';
import CommunityGuidelinesPage from './pages/community-guidelines';
import CreativesPage from './pages/creatives';
import HomePage from './pages/home';
import LandingPage from './pages/landing';
import MessagesPage from './pages/messages';
import NotFound from './pages/not-found';
import OnboardingPage from './pages/onboarding';
import PrivacyPolicyPage from './pages/privacy-policy';
import NewProjectPage from './pages/project/new';
import ShowProjectPage from './pages/project/show';
import UpdateProjectPage from './pages/project/update';
import ResetPassword from './pages/reset-password';
import SignInPage from './pages/sign-in';
import SignUpPage from './pages/sign-up';
import TermsOfUsePage from './pages/terms-of-use';
import ConfirmUserPage from './pages/user/confirmUser';
import CurrentUserProfilePage from './pages/user/currentUser';
import UserProfilePage from './pages/user/show';
import { initGoogleAnalytics } from './services/googleAnalytics';
import AllProjectResultsPage from './pages/project/all-results';
import AllUserResultsPage from './pages/user/all-results';

const Routes = () => {
  useEffect(() => initGoogleAnalytics(), []);

  return (
    <Router>
      <Switch>
        <PrivateRoute exact={true} path="/" component={HomePage} />
        <PublicRoute exact={true} path="/landing" component={LandingPage} />
        <PublicRoute exact={true} path="/sign-in" component={SignInPage} />
        <PublicRoute exact={true} path="/sign-up" component={SignUpPage} />
        <PublicRoute
          exact={true}
          path="/terms-of-use"
          component={TermsOfUsePage}
        />
        <PublicRoute
          exact={true}
          path="/privacy-policy"
          component={PrivacyPolicyPage}
        />
        <PublicRoute
          exact={true}
          path="/community-guidelines"
          component={CommunityGuidelinesPage}
        />
        <PublicRoute
          exact={true}
          path="/reset-password"
          component={ResetPassword}
        />
        <PrivateRoute
          exact={true}
          path="/project/new"
          component={NewProjectPage}
        />
        <ShareableRoute
          exact={true}
          path="/project/:projectId"
          component={ShowProjectPage}
        />
        <ShareableRoute
          exact={true}
          path="/all-project-results"
          component={AllProjectResultsPage}
        />
        <ShareableRoute
          exact={true}
          path="/all-user-results"
          component={AllUserResultsPage}
        />
        <PrivateRoute
          exact={true}
          path="/project/:projectId/update"
          component={UpdateProjectPage}
        />
        <PrivateRoute
          exact={true}
          path="/user"
          component={CurrentUserProfilePage}
        />
        <PrivateRoute
          exact={true}
          path="/onboarding"
          component={OnboardingPage}
        />
        <PrivateRoute path="/messages" component={MessagesPage} />
        <PublicRoute
          exact={true}
          path="/confirm-user"
          component={ConfirmUserPage}
        />
        <PrivateRoute
          exact={true}
          path="/user/:userId"
          component={UserProfilePage}
        />
        <PrivateRoute
          exact={true}
          path="/creatives"
          component={CreativesPage}
        />
        <PublicRoute component={NotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;

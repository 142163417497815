import React from 'react';

export default function SearchItem({ hit, components, pathname }) {
  return (
    <a
      href={`/${pathname}/${hit.id}`}
      target="_blank"
      rel="noreferrer"
      className="aa-ItemLink"
    >
      <div className="aa-ItemWrapper">
        <div className="aa-ItemContent">
          {hit.avatar && (
            <div className="aa-ItemIcon">
              <img
                src={hit.avatar}
                alt={`${pathname === 'project' ? hit.title : hit.name}`}
                width="40"
                height="40"
              />
            </div>
          )}
          <div className="aa-ItemContentBody">
            <div className="aa-ItemContentTitle">
              <components.Highlight
                hit={hit}
                highlightedTagName="mark"
                attribute={`${pathname === 'project' ? 'title' : 'name'}`}
              />
            </div>
            {pathname === 'user' && (
              <div className="aa-ItemContentDescription">
                <components.Highlight
                  hit={hit}
                  attribute="professionsCombined"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </a>
  );
}
